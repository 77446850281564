<template>
  <div class="page-content">
    <div class="row form-group">
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container1-1" ></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container1-2"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container1-3"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container1-4"></div>
      </div>
    </div>
    <div class="row form-group">
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container2-1"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container2-2"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container2-3"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container2-4"></div>
      </div>
    </div>
    <div class="row form-group">
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container3-1"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container3-2"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container3-3"></div>
      </div>
      <div style="display: inline-block;vertical-align: top;" class="col-lg-3">
        <div id="video-container3-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
var player = null;
export default {
  name: "HelloWorld",
  // data:function () {
  //     return {
  //         player: null,
  //     }
  // },
  props: {
    msg: String,
  },
  mounted: () => {
    console.group("mounted 组件挂载完毕状态===============》");
    new EZUIKit.EZUIKitPlayer({
      autoplay: true,
      id: "video-container1-1",
      template: "standard",
      accessToken:
        "at.0wvxb8ce0s0hp2dvcl72y2894hfor07b-1hr6svugrb-07tus9e-gcltahakz",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      // template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // // 视频上方头部控件
      // header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
      // plugin: ['talk'],                       // 加载插件，talk-对讲
      // // 视频下方底部控件
      // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
      // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
      // openSoundCallBack: data => console.log("开启声音回调", data),
      // closeSoundCallBack: data => console.log("关闭声音回调", data),
      // startSaveCallBack: data => console.log("开始录像回调", data),
      // stopSaveCallBack: data => console.log("录像回调", data),
      // capturePictureCallBack: data => console.log("截图成功回调", data),
      // fullScreenCallBack: data => console.log("全屏回调", data),
      // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
      // width: "30%",
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container1-2",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container1-3",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // 视频上方头部控件
      //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
      //plugin: ['talk'],                       // 加载插件，talk-对讲
      // 视频下方底部控件
      // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
      // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
      // openSoundCallBack: data => console.log("开启声音回调", data),
      // closeSoundCallBack: data => console.log("关闭声音回调", data),
      // startSaveCallBack: data => console.log("开始录像回调", data),
      // stopSaveCallBack: data => console.log("录像回调", data),
      // capturePictureCallBack: data => console.log("截图成功回调", data),
      // fullScreenCallBack: data => console.log("全屏回调", data),
      // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container1-4",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });

    //第二行
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container2-1",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container2-2",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container2-3",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container2-4",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    //第三行
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container3-1",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container3-2",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container3-3",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
    new EZUIKit.EZUIKitPlayer({
      autoplay: false,
      id: "video-container3-4",
      accessToken:
        "at.1sgf43y606b5ggnn3dpw0udlc3ozemrr-54wpz96iz0-02xwt5p-olnyrysaw",
      url: "ezopen://open.ys7.com/G62834014/1.hd.live",
      template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      // width: 600,
      height: 300,
    });
  },
  methods: {
    change() {
      player.stop();
      // 切换为直播
      player.play({
        url: "ezopen://open.ys7.com/244640009/1.live",
      });
    },
    con(){
      console.log(1);
    }
  },
};
</script>
<style scoped>
.home {
  width: calc(30%);
  height: 800px;
  /* margin: 0px; */
  margin-left: 20px;
  text-align: left;
}
.home1 {
  width: calc(30%);
  height: 800px;
  /* margin: 0px auto; */
  text-align: left;
}
</style>
